import React, { Fragment, useContext } from 'react';
import { PublisherDetailContext } from "../../context/PublisherDetailContext";
import Grid from "@material-ui/core/Grid";
import ProgressBar from './../../components/Common/ProgressBar/ProgressBar.component';
import CollectionInfo from "./../../components/Collection/CollectionInfo/CollectionInfo.component";
import Game from './../../components/Games/Game/Game.component';
import AlphabetControls from '../../components/Common/Alphabets/Alphabets.component';
import Box from '@material-ui/core/Box';
import { Pagination } from '@material-ui/lab';
import Meta from '../../components/Common/Meta/Meta.component';


const PublisherDetail = () => {
    const { doneFetchPublisher, doneFetchPublisherGames, publisher, publisherGames, page, setPage, pages } = useContext(PublisherDetailContext);
    return (
        <Fragment>
            <Meta title='[itemName]' description={`[itemName]'s games - [siteName]`}></Meta>
            <AlphabetControls context={PublisherDetailContext} />
            {doneFetchPublisher && doneFetchPublisherGames ? (
                <Fragment>
                    <CollectionInfo item={publisher} />
                    <Grid container spacing={3}>
                        <Game games={publisherGames} />
                    </Grid>
                </Fragment>
            ) : (
                <ProgressBar />
            )}
            <Box my={3} display="flex" alignItems="center" justifyContent="center">
                <Pagination page={page} onChange={(event, value) => { setPage(value); }} count={pages} />
            </Box>
        </Fragment>
    );
}

export default PublisherDetail;