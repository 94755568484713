import React, { Fragment, useContext, useState } from "react";
import { GenreDetailContext } from "../../context/GenreDetailContext";
import Grid from "@material-ui/core/Grid";
import ProgressBar from "./../../components/Common/ProgressBar/ProgressBar.component";
import CollectionInfo from "./../../components/Collection/CollectionInfo/CollectionInfo.component";
import Game from "./../../components/Games/Game/Game.component";
import AlphabetControls from '../../components/Common/Alphabets/Alphabets.component';
import Box from '@material-ui/core/Box';
import { Pagination } from '@material-ui/lab';
import Meta from "../../components/Common/Meta/Meta.component";

const GenreDetail = () => {
  const { doneFetchGenre, doneFetchGenreGames, genre, genreGames, page, setPage, pages, loading } = useContext(
    GenreDetailContext
  );

  return (
    <Fragment>
      <Meta title='List of all [itemName] games' description={`List of all [itemName] games on [siteName]`}></Meta>
      <AlphabetControls context={GenreDetailContext} />
      {doneFetchGenre && doneFetchGenreGames ? (
        <Fragment>
          <CollectionInfo item={genre} />
          <Grid container spacing={3}>
            <Game games={genreGames} />
          </Grid>
        </Fragment>
      ) : (
        <ProgressBar />
      )}
      <Box my={3} display="flex" alignItems="center" justifyContent="center">
        <Pagination page={page} onChange={(event, value) => { setPage(value); }} count={pages} />
      </Box>
    </Fragment>
  );
};

export default GenreDetail;
