import React, { Fragment, useContext } from 'react';
import { PlatformDetailContext } from "../../context/PlatformDetailContext";
import Grid from "@material-ui/core/Grid";
import ProgressBar from './../../components/Common/ProgressBar/ProgressBar.component';
import CollectionInfo from "./../../components/Collection/CollectionInfo/CollectionInfo.component";
import Game from './../../components/Games/Game/Game.component';
import AlphabetControls from '../../components/Common/Alphabets/Alphabets.component';
import Box from '@material-ui/core/Box';
import { Pagination } from '@material-ui/lab';
import Meta from '../../components/Common/Meta/Meta.component';

const PlatformDetail = () => {
  const { doneFetchPlatform, doneFetchPlatformGames, platform, platformGames, page, setPage, pages } = useContext(PlatformDetailContext);
  return (
    <Fragment>
      <Meta title='List of all [itemName] games' description={`List of all [itemName] games on [siteName]`}></Meta>
      <AlphabetControls context={PlatformDetailContext} />
      {doneFetchPlatform && doneFetchPlatformGames ? (
        <Fragment>
          <CollectionInfo item={platform} />
          <Grid container spacing={3}>
            <Game games={platformGames} />
          </Grid>
        </Fragment>
      ) : (
        <ProgressBar />
      )}
      <Box my={3} display="flex" alignItems="center" justifyContent="center">
        <Pagination page={page} onChange={(event, value) => { setPage(value); }} count={pages} />
      </Box>
    </Fragment>
  );
}

export default PlatformDetail;