import React, { Fragment, useContext } from "react";
import { GamesContext } from "./../../context/GamesContext";
import Grid from "@material-ui/core/Grid";
import SearchBar, { SearchFilters } from "./../../components/Games/SearchBar/SearchBar.component";
import ListOfGames from "./../../components/Games/ListsOfGames/ListsOfGames.component";
import Game from "./../../components/Games/Game/Game.component";
import ProgressBar from "../../components/Common/ProgressBar/ProgressBar.component";
import Message from "./../../components/Common/Message/Message.component";
import "./HomePage.styles.scss";
import { IconButton, TextField } from "@material-ui/core";
import ArrowBack from '@material-ui/icons/ArrowBack';
import Meta from "../../components/Common/Meta/Meta.component";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom";
import SearchPage from "../Search/SearchPage.component";
import GenresContextProvider from "../../context/GenresContext";

const HomePage = () => {
  const {
    doneFetchPopularGames,
    doneFetchUpcomingGames,
    doneFetchNewGames,
    popularGames,
    upcomingGames,
    newGames
  } = useContext(GamesContext);

  return (
    <Fragment>
      <Meta></Meta>
      <Switch>
        <Route exact path='/search'>
          <IconButton onClick={() => window.location.replace('/')} aria-label="Back">
            <ArrowBack />
          </IconButton>
        </Route>
      </Switch>
      <SearchBar />
      <Switch>
        <Route exact path='/search'>
          <GenresContextProvider>
            <SearchFilters />
          </GenresContextProvider>
        </Route>
      </Switch>
      <Grid container spacing={3} style={{ marginTop: '1rem' }}>
        <Switch>
          <Route exact path='/'>
            {
              doneFetchPopularGames &&
                doneFetchUpcomingGames &&
                doneFetchNewGames ? (
                <ListOfGames
                  popularGames={popularGames}
                  upcomingGames={upcomingGames}
                  newGames={newGames}
                />
              ) :
                <ProgressBar />
            }
          </Route>
          <Route exact path='/search'>
            <SearchPage></SearchPage>
          </Route>
        </Switch>
      </Grid>
    </Fragment>
  );
};

export default HomePage;
