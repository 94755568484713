import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import { Pagination, PaginationItem } from '@material-ui/lab';

const AlphabetControls = ({ context }) => {
    const { alphabet, setAlphabet, setPage } = useContext(context);
    const alphabets = Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ#");
    const handleChange = (event, value) => {
        let selectedAlphabet = alphabets[value - 1];
        setAlphabet(selectedAlphabet === alphabet ? '' : selectedAlphabet);
        setPage(1);
    }

    return (
        <Box my={3} display="flex" alignItems="center" justifyContent="center">
            <Pagination page={alphabets.indexOf(alphabet) + 1} boundaryCount={alphabets.length} onChange={handleChange} count={alphabets.length} renderItem={(item) => (
                <PaginationItem {...item} page={alphabets[item.page - 1]} />
            )} />
        </Box>
    );
}

export default AlphabetControls;