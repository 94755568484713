import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import defaultImage from '../../../assets/images/backgound-image.jpg'
import { siteInfo } from '../../../constants';

const Meta = ({
    title = '',
    description = siteInfo.defaultDescription,
    author,
    keywords = 'videogames, genres, companies, action, adventure, gamebrowser',
    image = window.location.origin + defaultImage,
    url = window.location.href,
}) => {
    const urlParams = useParams();
    const { siteName } = siteInfo;

    /* Add site name to titles. */
    title += (title ? ' - ' : '') + siteName;

    /* Replace placeholders. */
    const usePlaceholders = (str, replaces = {
        '[siteName]': siteName,
        '[itemName]': urlParams.name || urlParams.id || '',
    }) => {
        Object.entries(replaces).forEach(e => str = str.replaceAll(e[0], e[1]));
        return str;
    };

    title = usePlaceholders(title);
    description = usePlaceholders(description);

    return (
        <Helmet>
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            {author && <meta name="author" content={author} />}
            {keywords && <meta name="keywords" content={keywords} />}
            {image && <meta property="og:image" content={image} />}
            {url && <meta property="og:url" content={url} />}
            {title && <meta property="og:title" content={title} />}
            {description && <meta property="og:description" content={description} />}
            <meta property="og:type" content="website" />
            {/* Add more meta tags as needed */}
        </Helmet>
    );
};

export default Meta;