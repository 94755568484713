import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import SimpleReactLightbox from "simple-react-lightbox";
import Container from '@material-ui/core/Container';
import GamesContextProvider from './context/GamesContext';
import GamesDetailsContextProvider from './context/GameDetailsContext';
import GenresContextProvider from './context/GenresContext';
import GenreDetailContextProvider from './context/GenreDetailContext';
import PlatformsContextProvider from './context/PlatformsContext';
import PlatformDetailContextProvider from './context/PlatformDetailContext'
import PublishersContextProvider from './context/PublishersContext';
import PublisherDetailContextProvider from './context/PublisherDetailContext'
import HomePage from './pages/Home/HomePage.component';
import Header from './components/Common/Header/Header.component';
import GameDetails from './pages/GameDetails/GameDetails.component';
import Genres from './pages/Genres/Genres.component';
import GenreDetail from './pages/GenreDetail/GenreDetail.component';
import Platforms from './pages/Platforms/Platforms.component';
import PlatformDetail from './pages/PlatformDetail/PlatformDetail.component';
import Publishers from './pages/Publishers/Publishers.component';
import PublisherDetail from './pages/PublisherDetail/PublisherDetail.component';
import Footer from './components/Common/Footer/Footer.component';
import "./App.css";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Companies from './pages/Companies/Companies.component';
import CompaniesContextProvider from './context/CompaniesContext';
import CompanyDetail from './pages/CompanyDetail/CompanyDetail.component';
import SearchPage from './pages/Search/SearchPage.component';

const darkTheme = createTheme({
  palette: {
    type: 'dark',
  },
});

const App = () => (
  <ThemeProvider theme={darkTheme}>
    <BrowserRouter>
      <Header />
      <Container maxWidth="lg" className="main-container">
        <Switch>
          <Route exact path='/'>
            <GamesContextProvider>
              <HomePage />
            </GamesContextProvider>
          </Route>
          <Route exact path='/search'>
            <GamesContextProvider>
              <HomePage />
            </GamesContextProvider>
          </Route>
          <Route path='/games/details/:id/:name'>
            <SimpleReactLightbox>
              <GamesDetailsContextProvider>
                <GameDetails />
              </GamesDetailsContextProvider>
            </SimpleReactLightbox>
          </Route>
          <Route exact path='/genres'>
            <GenresContextProvider>
              <Genres />
            </GenresContextProvider>
          </Route>
          <Route path='/genres/:id/:name'>
            <GenreDetailContextProvider>
              <GenreDetail />
            </GenreDetailContextProvider>
          </Route>
          <Route exact path='/platforms'>
            <PlatformsContextProvider>
              <Platforms />
            </PlatformsContextProvider>
          </Route>
          <Route path='/platforms/:id/:name'>
            <PlatformDetailContextProvider>
              <PlatformDetail />
            </PlatformDetailContextProvider>
          </Route>
          <Route exact path='/publishers'>
            <PublishersContextProvider>
              <Publishers />
            </PublishersContextProvider>
          </Route>
          <Route path='/publishers/:id'>
            <PublisherDetailContextProvider>
              <PublisherDetail />
            </PublisherDetailContextProvider>
          </Route>
          <Route exact path='/companies'>
            <CompaniesContextProvider>
              <Companies />
            </CompaniesContextProvider>
          </Route>
          <Route path='/companies/:id'>
            <PublisherDetailContextProvider>
              <CompanyDetail />
            </PublisherDetailContextProvider>
          </Route>
        </Switch>
      </Container>
      <Footer />
    </BrowserRouter>
  </ThemeProvider>
)

export default App;
