import React, { useContext, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import { GenresContext } from '../../../context/GenresContext';
import { GamesContext } from '../../../context/GamesContext';

const useStyles = makeStyles({
  input: {
    '& label': {
      color: '#ffffff',
    },
    '& label.Mui-focused': {
      color: '#ffffff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff',
      },
      '& input': {
        color: '#ffffff',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff',
      },
    },
  },
});

const SearchBar = () => {
  const classes = useStyles();
  const { handleFilterChange, searchParams } = useContext(GamesContext);

  const [value, setValue] = useState(React.useMemo(
    () => searchParams.search || '',
    [searchParams.search],
  ));

  return (
    <TextField
      className={classes.input}
      type="text"
      id="q_game"
      name="search"
      label="Search for a game"
      margin="normal"
      variant="outlined"
      style={{ width: '100%' }}
      onKeyPress={(e) => handleFilterChange(e, true)}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

const SearchFilters = () => {
  const classes = useStyles();
  const { handleFilterChange, searchParams } = useContext(GamesContext);
  const { genres, doneFetchGenres } = useContext(GenresContext);

  const range = (start, stop, step = 1) => {
    const length = Math.ceil((stop - start) / step);
    return Array.from({ length }, (_, i) => (i * step) + start);
  }



  const years = React.useMemo(() => range(1900, (new Date).getFullYear() + 10).reverse().map(e => ({ name: e.toString() })));

  return (
    <Grid spacing={1} container>
      <Grid xs={12} sm={6} item>
        <Autocomplete
          options={genres}
          value={genres.find(v => v.id === searchParams.genres) || null}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField className={classes.input} {...params} label="Genre" variant="outlined" />}
          onChange={(e, v) => handleFilterChange({ name: 'genres', value: v ? v.id : '' })}
        />
      </Grid>
      <Grid xs={12} sm={6} item>
        <Autocomplete
          options={years}
          value={years.find(v => v.name === searchParams.year) || null}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField className={classes.input} {...params} label="Year" variant="outlined" />}
          onChange={(e, v = {}) => handleFilterChange({ name: 'year', value: v ? v.name : '' })}
        />
      </Grid>
    </Grid >
  )

}

export default SearchBar;
export { SearchFilters };