import React, { Fragment } from "react";
import Subtitle from "./../Subtitle/Subtitle.component";
import "./Lists.styles.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";

const TextList = ({ title, items, platforms, collection, to }) => (
  <Fragment>
    <Subtitle text={title} />
    <ul className="list">
      {items && collection
        ? items.slice(0, 3).map((item) => (
          <li className="list__item" key={item.id}>
            {item.name}
          </li>
        ))
        : items && platforms
          ? items.map((item) => (
            <li className="list__item" key={item.platform.id}>
              {item.platform.name}
            </li>
          ))
          : items.map((item, i) => (
            <li className="list__item" key={item.id + '_' + i}>
              {to ? (<Link to={`${to}/${item.id}/${item.name}`}>{item.name}</Link>) : <>{item.name}</>}
            </li>
          ))}
    </ul>
  </Fragment>
);

export default TextList;
