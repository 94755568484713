import React, { Fragment, useContext } from 'react';
import { CompaniesContext } from './../../context/CompaniesContext';
// import Grid from "@material-ui/core/Grid";
// import CollectionCard from './../../components/Collection/CollectionCard/CollectionCard.component';
import ProgressBar from "../../components/Common/ProgressBar/ProgressBar.component";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AlphabetControls from '../../components/Common/Alphabets/Alphabets.component';
import Box from '@material-ui/core/Box';
import { Pagination } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Meta from '../../components/Common/Meta/Meta.component';

const Companies = () => {
    const { doneFetchCompanies, companies, page, setPage, pages } = useContext(CompaniesContext);
    return (
        <Fragment>
            <Meta title='Companies' description='List of all video game companies on [siteName]'></Meta>
            <h1 className="text-center">List of Video Game Companies</h1>
            <AlphabetControls context={CompaniesContext} />
            {
                doneFetchCompanies && companies ?
                    // <Grid container spacing={2}>
                    //     <CollectionCard link="companies" items={companies} />
                    // </Grid>
                    <TableContainer component={Paper} className='bg-transparent backdrop-1'>
                        <Table size="small" aria-label="companies">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='bg-dark'>Company</TableCell>
                                    <TableCell className='bg-dark'>Games</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {companies.map((row) => (
                                    <TableRow hover key={row.name}>
                                        <TableCell component="th" scope="row">
                                            <Link component={RouterLink} color='inherit' underline='none' to={`/companies/${row.name}`}>{row.name}</Link>
                                        </TableCell>
                                        <TableCell>{100 + '+ games'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <ProgressBar />
            }
            <Box my={3} display="flex" alignItems="center" justifyContent="center">
                <Pagination page={page} onChange={(event, value) => { setPage(value); }} count={pages} />
            </Box>
        </Fragment>
    );
}

export default Companies;
