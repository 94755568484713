import React, { createContext, useState, useEffect } from 'react';
import { publishersGet } from './../constants';

export const CompaniesContext = createContext();

const CompaniesContextProvider = ({ children }) => {
  const [doneFetchCompanies, setDoneFetchCompanies] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [alphabet, setAlphabet] = useState('');

  //Life Cycle
  useEffect(() => getCompanies(), [page, alphabet]);

  //Fetch
  const getCompanies = () => {
    fetch(publishersGet()
      + `&page=${page}`
      + (alphabet ? `&alphabet=${encodeURIComponent(alphabet)}` : ''))
      .then(res => res.json())
      .then(data => {
        setDoneFetchCompanies(true);
        setCompanies(data.results)
        setPages(data.pages);
      })
      .catch(error => console.log(error))
  }

  return (
    <CompaniesContext.Provider value={{ doneFetchCompanies, companies, pages, page, setPage, alphabet, setAlphabet }}>
      {children}
    </CompaniesContext.Provider>
  )

}

export default CompaniesContextProvider;