import React, { Fragment, useContext } from 'react';
import { GenresContext } from './../../context/GenresContext';
import Grid from "@material-ui/core/Grid";
// import CollectionCard from './../../components/Collection/CollectionCard/CollectionCard.component';
import ProgressBar from "../../components/Common/ProgressBar/ProgressBar.component";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "react-router-dom";
import Meta from '../../components/Common/Meta/Meta.component';

const Genre = () => {
  const { doneFetchGenres, genres } = useContext(GenresContext);

  const categories = genres.reduce((acc, curr) => {
    if (!acc[curr.category]) {
      acc[curr.category] = [];
    }
    acc[curr.category].push(curr);
    return acc;
  }, {});

  return (
    <Fragment>
      <Meta title='Genres' description='List of all video game genres on [siteName]'></Meta>
      <h1 className="text-center">Genres</h1>
      {
        doneFetchGenres && genres ?
          // <Grid container spacing={2}>
          //   <CollectionCard link="genres" items={genres} />
          // </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
              <Paper style={{ position: 'sticky', top: '90px' }} className='bg-transparent backdrop-1' elevation={3}>
                <List dense>
                  {
                    Object.keys(categories).map((e, i) => {
                      return <ListItem button component='a' href={`#category-${i}`}>
                        <ListItemText
                          primary={e}
                        />
                      </ListItem>
                    })
                  }
                </List>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={10}>
              {
                Object.keys(categories).map((e, i) =>
                  <div key={i}>
                    <h3 id={`category-${i}`} className={i === 0 ? 'mt-0' : ''}>{`${e}`}</h3>
                    <List className='backdrop-1' disablePadding>
                      {
                        categories[e].map((e, i) =>
                          <ListItem key={i} button component={Link} to={`/genres/${e.id}/${e.name}`}>
                            <ListItemText
                              primary={`${e.name}` + (e.count ? ` (${100}+ games)` : '')}
                            />
                          </ListItem>
                        )
                      }
                    </List>
                  </div>
                )
              }
            </Grid>
          </Grid>
          : <ProgressBar />
      }
    </Fragment >
  );
}

export default Genre;