import React, { createContext, useState, useEffect } from 'react';
import { specificPlatformGet, samplePlatformGamesGet } from '../constants';

export const PlatformDetailContext = createContext();

const PlatformDetailContextProvider = ({ children }) => {
  const platform_id = window.location.pathname.split('/')[2];
  const [doneFetchPlatform, setDoneFetchPlatform] = useState(false);
  const [doneFetchPlatformGames, setDoneFetchPlatformGames] = useState(false);
  const [platform, setPlatform] = useState([]);
  const [platformGames, setPlatformGames] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [alphabet, setAlphabet] = useState('');

  //Life cycle
  useEffect(() => getPlatform(platform_id), [platform_id]);
  useEffect(() => getPlatformGames(platform_id), [platform_id, page, alphabet]);

  //Fetch
  const getPlatform = (platform_id) => {
    fetch(specificPlatformGet(platform_id))
      .then(res => res.json())
      .then(data => {
        setDoneFetchPlatform(true)
        setPlatform(data)
      })
      .catch(error => console.log(error))
  }

  const getPlatformGames = (platform_id) => {
    setDoneFetchPlatformGames(false);
    fetch(samplePlatformGamesGet(platform_id)
      + `&page=${page}`
      + (alphabet ? `&alphabet=${encodeURIComponent(alphabet)}` : '')
    )
      .then(res => res.json())
      .then(data => {
        setDoneFetchPlatformGames(true)
        setPlatformGames(data.results)
        setPages(data.pages);
      })
  }

  return (
    <PlatformDetailContext.Provider value={{ doneFetchPlatform, doneFetchPlatformGames, platform, platformGames, pages, page, setPage, alphabet, setAlphabet }}>
      {children}
    </PlatformDetailContext.Provider>
  )
}

export default PlatformDetailContextProvider;