import React, { Fragment, useContext } from "react";
import { GamesContext } from "../../context/GamesContext";
import Grid from "@material-ui/core/Grid";
import Game from "../../components/Games/Game/Game.component";
import ProgressBar from "../../components/Common/ProgressBar/ProgressBar.component";
import Message from "../../components/Common/Message/Message.component";
import "./SearchPage.styles.scss";
import Meta from "../../components/Common/Meta/Meta.component";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


const SearchPage = () => {
  const {
    doneFetchSearchedGames,
    searchedGames,
    clearSearch
  } = useContext(GamesContext);

  return (
    <Fragment>
      <Meta title="Search"></Meta>
      {!doneFetchSearchedGames ?
        <ProgressBar />
        : searchedGames.length ? (
          <Game games={searchedGames} />
        ) : (
          <Message text="No results found" />
        )}
    </Fragment>
  );
};

export default SearchPage;
