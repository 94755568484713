import React, { createContext, useState, useEffect } from 'react';
import { popularGamesGet, upcomingGamesGet, newGamesGet, searchGameGet } from './../constants';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

export const GamesContext = createContext();

const GamesContextProvider = ({ children }) => {
  const [doneFetchPopularGames, setdoneFetchPopularGames] = useState(false);
  const [doneFetchUpcomingGames, setdoneFetchUpcomingGames] = useState(false);
  const [doneFetchNewGames, setdoneFetchNewGames] = useState(false);
  const [popularGames, setPopularGames] = useState([]);
  const [upcomingGames, setUpcomingGames] = useState([]);
  const [newGames, setNewGames] = useState([]);
  const [doneFetchSearchedGames, setdoneFetchSearchedGames] = useState(false);
  const [searchedGames, setSearchedGames] = useState([]);

  const history = useHistory();
  const location = useLocation();

  const [searchParams, setSearchParams] = useState(() => {
    const initSearchParams = {};

    //Sync from url initially.
    for (let [key, value] of (new URLSearchParams(location.search)).entries()) {
      if (value) initSearchParams[key] = value;
    }

    return initSearchParams;
  });

  //Life Cycle
  useEffect(() => getPopularGames(), []);
  useEffect(() => getUpcomingGames(), []);
  useEffect(() => getNewGames(), []);

  //Fetchs
  const getPopularGames = () => {
    fetch(popularGamesGet())
      .then(res => res.json())
      .then(data => {
        setdoneFetchPopularGames(true);
        setPopularGames(data.results)
      })
      .catch(error => console.log(error));
  }
  const getUpcomingGames = () => {
    fetch(upcomingGamesGet())
      .then(res => res.json())
      .then(data => {
        setdoneFetchUpcomingGames(true);
        setUpcomingGames(data.results)
      })
      .catch(error => console.log(error));
  }
  const getNewGames = () => {
    fetch(newGamesGet())
      .then(res => res.json())
      .then(data => {
        setdoneFetchNewGames(true);
        setNewGames(data.results)
      })
      .catch(error => console.log(error));
  }
  const getSearchedGames = (q_game) => {
    fetch(searchGameGet(q_game))
      .then(res => res.json())
      .then(data => {
        setdoneFetchSearchedGames(true);
        setSearchedGames(data.results)
      })
      .catch(error => console.log(error));
  }

  const handleFilterChange = (e, onEnterOnly = false) => {
    if (onEnterOnly) {
      //Handle this change on enter only.
      if (e.key !== 'Enter') return;
      if (location.pathname !== '/search') history.push('/search');
    }

    const filterName = e.name !== undefined ? e.name : (e.target?.name || e.target?.id);
    const value = e.value !== undefined ? e.value : e.target?.value;

    setSearchParams((prev) => ({
      ...prev,
      [filterName]: value,
    }))
  }

  //Search on change, update url.
  useEffect(() => {
    if (location.pathname !== '/search') return;

    const urlSearchString = new URLSearchParams(location.search).toString();
    const stateSearchString = new URLSearchParams(searchParams).toString();

    if (!stateSearchString) return window.location.replace('/');

    if (urlSearchString !== stateSearchString) {
      //Sync search state to url.
      history.replace({
        search: stateSearchString
      });
    }

    getSearchedGames(stateSearchString);
  }, [searchParams])

  return (
    <GamesContext.Provider value={{ doneFetchPopularGames, doneFetchUpcomingGames, doneFetchNewGames, doneFetchSearchedGames, popularGames, upcomingGames, newGames, searchedGames, searchParams, handleFilterChange }}>
      {children}
    </GamesContext.Provider>
  )
}

export default GamesContextProvider;